import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Trans } from '@lingui/macro';
import classames from 'classnames';

import { useStateValue } from 'state/State';

const AdminDropdownMenu = () => {
  const [{ user }] = useStateValue();
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);

  if (
    !(
      user?.valid_roles.admin ||
      user?.valid_roles.quiz_editor ||
      user?.valid_roles.special_quiz_editor ||
      user?.valid_roles.answer_reviewer ||
      (process.env.REACT_APP_NATIONAL_RANKING === 'true' &&
        (user?.valid_roles.admin || user?.valid_roles.national_ranking_manager))
    )
  ) {
    return null;
  }

  const hasQuizManagement =
    user.valid_roles.admin ||
    user.valid_roles.quiz_editor ||
    user.valid_roles.special_quiz_editor ||
    user.valid_roles.answer_reviewer;

  const hasNationalRankingManagement =
    process.env.REACT_APP_NATIONAL_RANKING === 'true' &&
    (user.valid_roles.admin || user.valid_roles.national_ranking_manager);

  return (
    <div
      className={classames('navbar-item', 'has-dropdown', {
        'is-active': menuDropdownOpen,
      })}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setMenuDropdownOpen(false);
        }}
        display="flex"
      >
        <button
          className="navbar-link"
          type="button"
          onClick={() => {
            setMenuDropdownOpen(!menuDropdownOpen);
          }}
        >
          <i className="fa-fw fa-solid fa-gear" /> <Trans>Administração</Trans>
        </button>
      </OutsideClickHandler>
      <div className="navbar-dropdown is-right">
        {user.valid_roles.admin && (
          <>
            <div className="navbar-item">
              <Trans>Administração</Trans>
            </div>
            <Link to="/admin/users" className="navbar-item">
              <i className="fa-fw fa-solid fa-users" />
              &nbsp;<Trans>Utilizadores</Trans>
            </Link>
            <Link to="/admin/notifications" className="navbar-item">
              <i className="fa-fw fa-solid fa-bell" />
              &nbsp;<Trans>Notificações</Trans>
            </Link>
            <Link to="/admin/seasons" className="navbar-item">
              <i className="fa-fw fa-solid fa-calendar"></i>
              &nbsp;<Trans>Temporadas</Trans>
            </Link>
            <hr className="navbar-divider" />
          </>
        )}
        {hasQuizManagement && (
          <>
            <div className="navbar-item">
              <Trans>Gestão de Quizzes</Trans>
            </div>
            {(user.valid_roles.admin ||
              user.valid_roles.quiz_editor ||
              user.valid_roles.answer_reviewer) && (
              <Link to="/admin/quizzes" className="navbar-item">
                <i className="fa-fw fa-solid fa-circle-question" />
                &nbsp;<Trans>Quizzes</Trans>
              </Link>
            )}
            {(user.valid_roles.admin ||
              user.valid_roles.special_quiz_editor ||
              user.valid_roles.answer_reviewer) && (
              <Link to="/admin/special-quizzes" className="navbar-item">
                <i className="fa-fw fa-solid fa-circle-question" />
                &nbsp;<Trans>Quizzes especiais</Trans>
              </Link>
            )}
            {(user.valid_roles.admin || user.valid_roles.quiz_editor) && (
              <Link to="/admin/questions" className="navbar-item">
                <i className="fa-fw fa-solid fa-magnifying-glass" />
                &nbsp;<Trans>Pesquisa de perguntas</Trans>
              </Link>
            )}
            {(user.valid_roles.admin || user.valid_roles.quiz_editor) && (
              <Link to="/admin/external-questions" className="navbar-item">
                <i className="fa-fw fa-solid fa-magnifying-glass" />
                &nbsp;
                <Trans>Pesquisa de perguntas externas</Trans>
              </Link>
            )}
          </>
        )}
        {hasQuizManagement && hasNationalRankingManagement && (
          <hr className="navbar-divider" />
        )}
        {hasNationalRankingManagement && (
          <>
            <div className="navbar-item">
              <Trans>Gestão de Ranking Nacional</Trans>
            </div>
            <Link to="/admin/national-ranking/ranking" className="navbar-item">
              <i className="fa-fw fa-solid fa-star" />
              &nbsp;<Trans>Rankings mensais</Trans>
            </Link>
            <Link to="/admin/national-ranking/events" className="navbar-item">
              <i className="fa-fw fa-solid fa-calendar"></i>
              &nbsp;<Trans>Provas mensais</Trans>
            </Link>
            <Link to="/admin/national-ranking/players" className="navbar-item">
              <i className="fa-fw fa-solid fa-users" />
              &nbsp;<Trans>Jogadores</Trans>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDropdownMenu;
