import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import classames from 'classnames';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { useStateValue } from 'state/State';
import ApiRequest from 'utils/ApiRequest';
import Loading from 'components/Loading';
import Error from 'components/Error';
import PageHeader from 'components/PageHeader';
import EmptyState from 'components/EmptyState';
import EditUserModal from './modals/EditUserModal';

import classes from './Users.module.scss';

const Users = () => {
  const [{ user }] = useStateValue();
  const [error, setError] = useState(false);
  const [users, setUsers] = useState();
  const [customers, setCustomers] = useState();
  const [customer, setCustomer] = useState(user.customer.id);
  const [{ user: authUser }] = useStateValue();
  const [userToEdit, setUserToEdit] = useState();
  const [order, setOrder] = useState({
    column: 'id',
    direction: 'asc',
  });
  const [impersonating, setImpersonating] = useState(
    Boolean(Cookies.get('impersonating'))
  );
  const [loadingImpersonation, setLoadingImpersonation] = useState(false);

  useEffect(() => {
    ApiRequest.get('customers')
      .then(({ data: customersData }) => {
        setCustomers(customersData);
      })
      .catch(({ response }) => {
        setError(response?.status);
      });
  }, []);

  useEffect(() => {
    setUsers();
    ApiRequest.get(`users?customer_id=${customer}`)
      .then(({ data: usersData }) => {
        ApiRequest.get('logs?versions=true')
          .then(({ data: versionsData }) => {
            setUsers(
              usersData.map((item) => ({
                ...item,
                version: versionsData[item.id] || '-',
              }))
            );
          })
          .catch(({ response }) => {
            setError(response?.status);
          });
      })
      .catch(({ response }) => {
        setError(response?.status);
      });
  }, [customer]);

  const sortUsersBy = (column) => {
    let newOrder;
    if (order && order.column === column) {
      if (
        (order.column !== 'fullName' && order.direction === 'asc') ||
        (order.column === 'fullName' && order.direction === 'desc')
      ) {
        newOrder = {
          column: 'id',
          direction: 'asc',
        };
      } else {
        newOrder = {
          column,
          direction: order.direction === 'asc' ? 'desc' : 'asc',
        };
      }
    } else {
      newOrder = { column, direction: column === 'fullName' ? 'asc' : 'desc' };
    }
    const sortedUsers = [].concat(users).sort((a, b) => {
      let aValue;
      let bValue;
      if (newOrder.column === 'fullName') {
        aValue = `${a.name} ${a.surname}`;
        bValue = `${b.name} ${b.surname}`;
      } else {
        aValue = get(a, newOrder.column, '');
        bValue = get(b, newOrder.column, '');
      }
      if (newOrder.direction === 'asc') {
        if (typeof aValue === 'string') {
          return aValue.localeCompare(bValue);
        }
        return aValue - bValue;
      }
      if (typeof aValue === 'string') {
        return bValue.localeCompare(aValue);
      }
      return bValue - aValue;
    });
    setUsers(sortedUsers);
    setOrder(newOrder);
  };

  const impersonate = (userId) => {
    setLoadingImpersonation(true);
    setImpersonating(true);
    ApiRequest.post('impersonate', { id: userId })
      .then(({ data }) => {
        const validity = Math.round(
          (Date.parse(data.expires_at.replace(/ /g, 'T')) - Date.now()) /
            1000 /
            60 /
            60 /
            24 /
            2
        );
        const adminToken = Cookies.get('AUTH-TOKEN');
        Cookies.set('ADMIN-TOKEN', adminToken, {
          expires: validity,
          sameSite: 'strict',
        });
        Cookies.set('AUTH-TOKEN', data.access_token, {
          expires: validity,
          sameSite: 'strict',
        });
        Cookies.set('impersonating', true, {
          expires: validity,
          sameSite: 'strict',
        });
        document.location.href = '/';
      })
      .catch(() => {
        setImpersonating(false);
        toast.error(
          <Trans>Não foi possível usar a identidade deste utilizador.</Trans>
        );
      });
  };

  if (!authUser) {
    return <Error status={401} />;
  }

  if (!authUser.valid_roles.admin) {
    return <Error status={403} />;
  }

  if (error) {
    return <Error status={error} />;
  }

  if (loadingImpersonation) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title={<Trans>Utilizadores</Trans>} />
      <div className="section content">
        {!customers ? (
          <Loading />
        ) : (
          <>
            <form className={classes.form}>
              <div className="field">
                <label className="label">
                  <Trans>Cliente</Trans>
                </label>
                <div className="control has-icons-left">
                  <div className="select">
                    <select
                      defaultValue={customer}
                      onChange={(event) => {
                        setCustomer(event.target.value);
                      }}
                    >
                      {customers.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="icon is-small is-left">
                    <i className="fa-fw fa-solid fa-bell" />
                  </div>
                </div>
              </div>
            </form>
            {!users ? (
              <Loading />
            ) : (
              <>
                {users.length ? (
                  <table className="table is-fullwidth is-hoverable">
                    <thead>
                      <tr>
                        <th className="sortable">
                          <button
                            type="button"
                            onClick={() => sortUsersBy('fullName')}
                          >
                            <Trans>Nome</Trans>
                            <span className="icon">
                              {order.column === 'fullName' &&
                                order.direction === 'asc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-a-z" />
                                  </span>
                                )}
                              {order.column === 'fullName' &&
                                order.direction === 'desc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-z-a" />
                                  </span>
                                )}
                              {order.column !== 'fullName' && (
                                <span>
                                  <i className="fa-solid fa-sort" />
                                </span>
                              )}
                            </span>
                          </button>
                        </th>
                        <th
                          className={classames(
                            classes.versionCell,
                            'sortable',
                            'is-hidden-mobile'
                          )}
                        >
                          <button
                            type="button"
                            onClick={() => sortUsersBy('version')}
                          >
                            <Trans>Versão</Trans>
                            <span className="icon">
                              {order.column === 'version' &&
                                order.direction === 'asc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-short-wide" />
                                  </span>
                                )}
                              {order.column === 'version' &&
                                order.direction === 'desc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-wide-short" />
                                  </span>
                                )}
                              {order.column !== 'version' && (
                                <span>
                                  <i className="fa-solid fa-sort" />
                                </span>
                              )}
                            </span>
                          </button>
                        </th>
                        <th
                          className={classames(
                            classes.permissionsCell,
                            'is-hidden-touch'
                          )}
                        >
                          <Trans>Permissões</Trans>
                        </th>
                        <th
                          className={classames(
                            'sortable',
                            'is-hidden-touch',
                            classes.subscriptionCell
                          )}
                        >
                          <button
                            type="button"
                            onClick={() => sortUsersBy('roles.regular_player')}
                          >
                            <Trans>Quizzes da liga</Trans>
                            <span className="icon">
                              {order.column === 'roles.regular_player' &&
                                order.direction === 'asc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-short-wide" />
                                  </span>
                                )}
                              {order.column === 'roles.regular_player' &&
                                order.direction === 'desc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-wide-short" />
                                  </span>
                                )}
                              {order.column !== 'roles.regular_player' && (
                                <span>
                                  <i className="fa-solid fa-sort" />
                                </span>
                              )}
                            </span>
                          </button>
                        </th>
                        <th
                          className={classames(
                            'sortable',
                            'is-hidden-touch',
                            classes.subscriptionCell
                          )}
                        >
                          <button
                            type="button"
                            onClick={() =>
                              sortUsersBy('roles.special_quiz_player')
                            }
                          >
                            <Trans>Quizzes especiais</Trans>
                            <span className="icon">
                              {order.column === 'roles.special_quiz_player' &&
                                order.direction === 'asc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-short-wide" />
                                  </span>
                                )}
                              {order.column === 'roles.special_quiz_player' &&
                                order.direction === 'desc' && (
                                  <span>
                                    <i className="fa-solid fa-arrow-down-wide-short" />
                                  </span>
                                )}
                              {order.column !== 'roles.special_quiz_player' && (
                                <span>
                                  <i className="fa-solid fa-sort" />
                                </span>
                              )}
                            </span>
                          </button>
                        </th>
                        <th
                          className={classames(
                            'has-text-right',
                            classes.actionsCell
                          )}
                        >
                          <Trans>Acções</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => {
                        return (
                          <tr key={user.id}>
                            <td className="is-vertical-middle">
                              <div className={classes.userCellContent}>
                                <Link
                                  to={`/profile${
                                    user.id !== authUser.id ? `/${user.id}` : ''
                                  }`}
                                >
                                  <div className={classes.avatar}>
                                    {user.avatar ? (
                                      <img
                                        alt={`${user.name} ${user.surname}`}
                                        src={user.avatar}
                                      />
                                    ) : (
                                      <i className="fa-fw fa-solid fa-user" />
                                    )}
                                  </div>
                                  <span className={classes.username}>
                                    {user.name} {user.surname}
                                  </span>
                                </Link>
                                {user.inviter && (
                                  <>
                                    {' '}
                                    <small>
                                      <span
                                        className="icon"
                                        data-tooltip={`${t`Convidado por`}: ${
                                          user.inviter.name
                                        } ${user.inviter.surname} (${
                                          user.inviter.email
                                        })`}
                                      >
                                        <i className="fa-fw fa-solid fa-share" />
                                      </span>
                                    </small>
                                  </>
                                )}
                              </div>
                            </td>
                            <td
                              className={classames(
                                'is-vertical-middle',
                                'is-hidden-mobile',
                                classes.versionCell
                              )}
                            >
                              {user.version}
                            </td>
                            <td
                              className={classames(
                                'is-vertical-middle',
                                'is-hidden-touch',
                                classes.permissionsCell
                              )}
                            >
                              {user.valid_roles?.admin && (
                                <span
                                  className="icon"
                                  data-tooltip={t`Administrador`}
                                >
                                  <i className="fa-fw fa-solid fa-fire" />
                                </span>
                              )}
                              {user.valid_roles?.regular_player && (
                                <span
                                  className="icon"
                                  data-tooltip={`${t`Jogador regular`}`}
                                >
                                  <i className="fa-fw fa-solid fa-user" />
                                </span>
                              )}
                              {user.valid_roles?.special_quiz_player && (
                                <span
                                  className="icon"
                                  data-tooltip={`${t`Jogador de quizzes especiais`}`}
                                >
                                  <i className="fa-fw fa-solid fa-user" />
                                </span>
                              )}
                              {user.valid_roles?.national_ranking_manager && (
                                <span
                                  className="icon"
                                  data-tooltip={t`Gestor de Ranking Nacional`}
                                >
                                  <i className="fa-fw fa-solid fa-star" />
                                </span>
                              )}
                              {user.valid_roles?.quiz_editor && (
                                <span
                                  className="icon"
                                  data-tooltip={t`Editor de Quizzes`}
                                >
                                  <i className="fa-fw fa-solid fa-circle-question" />
                                </span>
                              )}
                              {user.valid_roles?.special_quiz_editor && (
                                <span
                                  className="icon"
                                  data-tooltip={t`Editor de Quizzes Especiais`}
                                >
                                  <i className="fa-fw fa-solid fa-circle-question" />
                                </span>
                              )}
                              {user.valid_roles?.answer_reviewer && (
                                <span
                                  className="icon"
                                  data-tooltip={t`Corrector`}
                                >
                                  <i className="fa-fw fa-solid fa-square-check" />
                                </span>
                              )}
                              {user.valid_roles?.blocked && (
                                <span
                                  className="icon"
                                  data-tooltip={t`Bloqueado`}
                                >
                                  <i className="fa-fw fa-solid fa-ban" />
                                </span>
                              )}
                            </td>
                            <td
                              className={classames(
                                classes.subscriptionCell,
                                'is-hidden-touch',
                                'is-vertical-middle'
                              )}
                            >
                              {get(user, 'roles.regular_player', '-')}
                            </td>
                            <td
                              className={classames(
                                classes.subscriptionCell,
                                'is-hidden-touch',
                                'is-vertical-middle'
                              )}
                            >
                              {get(user, 'roles.special_quiz_player', '-')}
                            </td>
                            <td className={classes.actionsCell}>
                              <div className="buttons has-addons is-pulled-right">
                                <button
                                  className="button"
                                  disabled={user.id === authUser.id}
                                  type="button"
                                  onClick={() =>
                                    setUserToEdit({
                                      ...user,
                                      roles: user.roles || {},
                                      newRoles: user.roles,
                                    })
                                  }
                                >
                                  <span className="icon">
                                    <i className="fa-fw fa-solid fa-pen-to-square" />
                                  </span>
                                </button>
                                {!impersonating && (
                                  <button
                                    className="button"
                                    disabled={user.id === authUser.id}
                                    type="button"
                                    onClick={() => impersonate(user.id)}
                                  >
                                    <span className="icon">
                                      <i className="fa-fw fa-solid fa-user-secret" />
                                    </span>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <EmptyState>
                    <Trans>Sem registos</Trans>
                  </EmptyState>
                )}
              </>
            )}
          </>
        )}
      </div>
      {userToEdit && (
        <EditUserModal
          userToEdit={userToEdit}
          setUserToEdit={setUserToEdit}
          users={users}
          setUsers={setUsers}
        />
      )}
    </>
  );
};

export default Users;
