import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import classames from 'classnames';
import Cookies from 'js-cookie';

import { catalogs } from 'utils/catalogs';
import { useStateValue } from 'state/State';
import logoLigaquiz from 'img/logo_ligaquiz.png';
import logoLigaquizDark from 'img/logo_ligaquiz_dark.png';
import logoEquizition from 'img/logo_equizition.png';
import logoEquizitionDark from 'img/logo_equizition_dark.png';
import AdminDropdownMenu from './Header/AdminDropdownMenu';
import UserDropdownMenu from './Header/UserDropdownMenu';
import classes from './Header.module.scss';

const isDarkMode =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

const Header = () => {
  const [{ settings, user }, dispatch] = useStateValue();
  const [menuBurgerOpen, setMenuBurgerOpen] = useState(false);

  const changeLanguage = (lang) => {
    Cookies.set('language', lang, { expires: 365, sameSite: 'strict' });
    dispatch({
      type: 'settings.language',
      payload: lang,
    });
  };

  const logo =
    process.env.REACT_APP_SLUG === 'equizition'
      ? isDarkMode
        ? logoEquizitionDark
        : logoEquizition
      : isDarkMode
      ? logoLigaquizDark
      : logoLigaquiz;

  const impersonating = Boolean(Cookies.get('impersonating'));

  return (
    <header>
      <nav
        className={classames('navbar', 'is-fixed-top', 'has-shadow', {
          [classes.impersonating]: impersonating,
        })}
        role="navigation"
        aria-label={t`Navegação primária`}
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            {user?.customer.logo ? (
              <img src={user.customer.logo} alt={user.customer.name} />
            ) : (
              <img src={logo} alt="logo" />
            )}
          </Link>
          <div className={classes.burgerWrapper}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setMenuBurgerOpen(false);
              }}
            >
              <button
                className={classames('navbar-burger', classes.burger)}
                type="button"
                onClick={() => {
                  setMenuBurgerOpen(!menuBurgerOpen);
                }}
                aria-label={t`Menu`}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </OutsideClickHandler>
          </div>
        </div>

        <div
          className={classames('navbar-menu', {
            'is-active': menuBurgerOpen,
          })}
        >
          <div className="navbar-start">
            {user && (
              <Link to="/rules" className="navbar-item">
                <i className="fa-fw fa-solid fa-bullhorn" />
                &nbsp;<Trans>Regras</Trans>
              </Link>
            )}
            {user &&
              (user.valid_roles.admin || user.valid_roles.regular_player) && (
                <>
                  <Link to="/ranking" className="navbar-item">
                    <i className="fa-fw fa-solid fa-gem" />
                    &nbsp;<Trans>Classificação</Trans>
                  </Link>
                  <Link to="/cup" className="navbar-item">
                    <i className="fa-fw fa-solid fa-trophy" />
                    &nbsp;<Trans>Taça</Trans>
                  </Link>
                  <Link to="/genre-rankings" className="navbar-item">
                    <i className="fa-fw fa-solid fa-shield" />
                    &nbsp;<Trans>Rankings temáticos</Trans>
                  </Link>
                </>
              )}
            {user && (
              <Link to="/profile" className="navbar-item">
                <i className="fa-fw fa-solid fa-user" />
                &nbsp;<Trans>O meu perfil</Trans>
              </Link>
            )}
            {!user && process.env.REACT_APP_NATIONAL_RANKING === 'true' && (
              <Link to="/national-ranking/" className="navbar-item">
                <i className="fa-fw fa-solid fa-medal" />
                &nbsp;<Trans>Ranking Nacional</Trans>
              </Link>
            )}
            {!user && process.env.REACT_APP_QUIZ_NATIONS === 'true' && (
              <Link to="/quiz-nations/" className="navbar-item">
                <i className="fa-fw fa-solid fa-earth-europe" />
                &nbsp;<Trans>Quiz Nations</Trans>
              </Link>
            )}
          </div>
          <div className="navbar-end">
            {Object.keys(catalogs).length > 1 && (
              <div className="navbar-item">
                <div className="buttons are-small">
                  {Object.keys(catalogs).map((language) => (
                    <button
                      key={language}
                      disabled={settings.language === language}
                      className="button"
                      type="button"
                      onClick={() => changeLanguage(language)}
                    >
                      {language.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {!user && (
              <div className="navbar-item">
                <div className="buttons are-small">
                  <Link to="/login/" className="button is-light">
                    <Trans>Entrar</Trans>
                  </Link>
                  <Link to="/register/" className="button is-primary">
                    <Trans>Registar</Trans>
                  </Link>
                </div>
              </div>
            )}
            <AdminDropdownMenu />
            <UserDropdownMenu />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
