import React from 'react';
import { t } from '@lingui/macro';

const renderMedia = (type, url, index) => {
  switch (type) {
    case 'image':
      return <img alt={t`Imagem da pergunta ${index}`} src={url} />;
    case 'audio':
      return <audio controls preload="none" src={url} />;
    case 'video':
      return <video controls preload="none" src={url} />;
    default:
      return '';
  }
};

export default renderMedia;
