import { t } from '@lingui/macro';

export const individualQuizTypeOptions = (type) =>
  ({
    cnq: t`Campeonato Nacional de Quiz`,
    eqc: t`Campeonato Europeu de Quiz`,
    inquizicao: t`Inquizição`,
    squizzed: t`Squizzed`,
    wqc: t`Campeonato Mundial de Quiz`,
    hot_100: t`HOT 100`,
    662211: t`66/22/11`,
    unlimited: t`Unlimited`,
    dairy: t`Quiz Dairy`,
  }[type]);

export const monthListOptions = (monthList) => {
  let monthListOptions = [];
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  while (!(currentYear === 2016 && currentMonth === 10)) {
    monthListOptions.push(
      `${currentYear}-${`${currentMonth}`.padStart(2, '0')}`
    );
    currentMonth--;
    if (!currentMonth) {
      currentMonth = 12;
      currentYear--;
    }
  }
  return monthListOptions.filter((month) => !monthList.includes(month));
};
