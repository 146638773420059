import { t } from '@lingui/macro';

export const getGenreTranslationAbbr = (slug) =>
  ({
    culture: t`Cul`,
    entertainment: t`Ent`,
    history: t`His`,
    science: t`Ciê`,
    sports: t`Des`,
    geography: t`Geo`,
    lifestyle: t`EdV`,
    society: t`Soc`,
  }[slug]);

export const getGenreTranslation = (slug) =>
  ({
    all: t`Todos`,
    null: t`Sem tema`,
    culture: t`Cultura`,
    entertainment: t`Entretenimento`,
    history: t`História`,
    science: t`Ciência`,
    sports: t`Desporto`,
    geography: t`Geografia`,
    lifestyle: t`Estilo de Vida`,
    society: t`Sociedade`,
    architecture: t`Arquitectura`,
    fine_arts: t`Belas Artes`,
    literature: t`Literatura`,
    dance: t`Dança`,
    operas_and_musicals: t`Óperas e Musicais`,
    theater: t`Teatro`,
    comics: t`Banda Desenhada`,
    music: t`Música`,
    tv: t`Televisão`,
    movies: t`Cinema`,
    fauna_and_flora: t`Fauna e Flora`,
    math: t`Matemática`,
    physics: t`Física`,
    chemistry: t`Química`,
    biology: t`Biologia`,
    geology: t`Geologia`,
    space: t`Espaço`,
    health_and_human_body: t`Saúde e Corpo Humano`,
    games_and_hobbies: t`Jogos e Passatempos`,
    design: t`Design`,
    fashion: t`Moda`,
    handicraft: t`Artesanato`,
    religion_and_beliefs: t`Religião e Crenças`,
    mythology: t`Mitologia`,
    traditions: t`Tradições`,
    food_and_beverage: t`Comida e Bebida`,
    tourism: t`Turismo`,
    museums: t`Museus`,
    transportation: t`Transportes`,
    philosophy: t`Filosofia`,
    social_sciences: t`Ciências Sociais`,
    technology_and_inventions: t`Tecnologia e Invenções`,
    products_and_brands: t`Produtos e Marcas`,
    press_and_social_networks: t`Comunicação Social e Redes Sociais`,
    nowadays_politics: t`Política Actual`,
    trivialities: t`Trivialidades`,
  }[slug]);
