import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';

import ApiRequest from 'utils/ApiRequest';
import renderMedia from 'utils/renderMedia';
import PageHeader from 'components/PageHeader';
import Loading from 'components/Loading';
import Error from 'components/Error';
import Markdown from 'components/Markdown';

import classes from './LeagueQuestions/LeagueQuestions.module.scss';

const LeagueQuestions = () => {
  const { season } = useParams();
  const [error, setError] = useState(false);
  const [questions, setQuestions] = useState();

  useEffect(() => {
    if (season) {
      ApiRequest.get(`questions?season=${season}`)
        .then(({ data: questions }) => {
          console.log(questions);
          setQuestions(questions);
        })
        .catch(({ response }) => {
          setError(response?.status);
        });
    }
  }, [season]);

  if (error) {
    return <Error status={error} />;
  }

  if (!questions) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        title={<Trans>Lista de perguntas</Trans>}
        subtitle={<Trans>Temporada {season}</Trans>}
      />
      <section className="section content">
        {questions.map((question, index) => (
          <div key={question.id} className={classes.question}>
            <h2 className="subtitle has-text-weight-bold">
              <Trans>Pergunta {index + 1}</Trans>
            </h2>
            <div>
              <Markdown content={question.content} />
            </div>
            {question.media_id && (
              <div
                className={classnames(
                  classes.media,
                  classes[question.media.type]
                )}
              >
                {renderMedia(question.media.type, question.media.url, index)}
              </div>
            )}
            <div className={classes.answer}>
              <strong>
                <Trans>Resposta correcta</Trans>:
              </strong>{' '}
              {question.answer}
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default LeagueQuestions;
