import React from 'react';
import { t } from '@lingui/macro';

const RankChange = ({ change }) => (
  <>
    {change === 0 && <strong>=</strong>}
    {change === undefined && (
      <span
        className="icon has-tooltip-right"
        data-tooltip={t`Nova entrada para o ranking`}
      >
        <i className="fa-fw fa-solid fa-star has-text-warning" />
      </span>
    )}
    {change > 0 && (
      <span
        className="icon has-tooltip-right"
        data-tooltip={`${t`Subiu`} ${Math.abs(change)} ${
          Math.abs(change) > 1 ? t`posições` : t`posição`
        }`}
      >
        <i className="fa-fw fa-solid fa-arrow-up has-text-success" />
      </span>
    )}
    {change < 0 && (
      <span
        className="icon has-tooltip-right"
        data-tooltip={`${t`Desceu`} ${Math.abs(change)} ${
          Math.abs(change) > 1 ? t`posições` : t`posição`
        }`}
      >
        <i className="fa-fw fa-solid fa-arrow-down has-text-danger" />
      </span>
    )}
  </>
);

export default RankChange;
