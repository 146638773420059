import { t } from '@lingui/macro';

export const getRegionTranslations = (code) =>
  ({
    'PT.AC': t`Açores`,
    'PT.AV': t`Aveiro`,
    'PT.BE': t`Beja`,
    'PT.BR': t`Braga`,
    'PT.BA': t`Bragança`,
    'PT.CB': t`Castelo Branco`,
    'PT.CO': t`Coimbra`,
    'PT.EV': t`Évora`,
    'PT.FA': t`Faro`,
    'PT.GU': t`Guarda`,
    'PT.LE': t`Leiria`,
    'PT.LI': t`Lisboa`,
    'PT.MA': t`Madeira`,
    'PT.PA': t`Portalegre`,
    'PT.PO': t`Porto`,
    'PT.SA': t`Santarém`,
    'PT.SE': t`Setúbal`,
    'PT.VC': t`Viana do Castelo`,
    'PT.VR': t`Vila Real`,
    'PT.VI': t`Viseu`,
    AD: t`Andorra`,
    AE: t`Emirados Árabes Unidos`,
    AF: t`Afeganistão`,
    AG: t`Antígua and Barbuda`,
    AL: t`Albânia`,
    AM: t`Arménia`,
    AO: t`Angola`,
    AR: t`Argentina`,
    AT: t`Áustria`,
    AU: t`Austrália`,
    AZ: t`Azerbaijão`,
    BA: t`Bósnia e Herzegovina`,
    BB: t`Barbados`,
    BD: t`Bangladesh`,
    BE: t`Bélgica`,
    BF: t`Burkina Faso`,
    BG: t`Bulgária`,
    BH: t`Bahrein`,
    BI: t`Burundi`,
    BJ: t`Benim`,
    BN: t`Brunei`,
    BO: t`Bolívia`,
    BR: t`Brasil`,
    BS: t`Bahamas`,
    BT: t`Butão`,
    BW: t`Botswana`,
    BY: t`Bielorrússia`,
    BZ: t`Belize`,
    CA: t`Canadá`,
    CD: t`República Democrática do Congo`,
    CF: t`República Centro-Africana`,
    CG: t`República do Congo`,
    CH: t`Suíça`,
    CI: t`Costa do Marfim`,
    CL: t`Chile`,
    CM: t`Camarões`,
    CN: t`China`,
    CO: t`Colômbia`,
    CR: t`Costa Rica`,
    CU: t`Cuba`,
    CV: t`Cabo Verde`,
    CY: t`Chipre`,
    CZ: t`Chéquia`,
    DE: t`Alemanha`,
    DJ: t`Djibuti`,
    DK: t`Dinamarca`,
    DM: t`Dominica`,
    DO: t`República Dominicana`,
    DZ: t`Argélia`,
    EC: t`Equador`,
    EE: t`Estónia`,
    EG: t`Egipto`,
    EH: t`Saara Ocidental`,
    ER: t`Eritreia`,
    ES: t`Espanha`,
    ET: t`Etiópia`,
    FI: t`Finlândia`,
    FJ: t`Fiji`,
    FM: t`Estados Federados da Micronésia`,
    FR: t`França`,
    GA: t`Gabão`,
    GB: t`Reino Unido`,
    GD: t`Granada`,
    GE: t`Geórgia`,
    GH: t`Gana`,
    GM: t`Gâmbia`,
    GN: t`Guiné`,
    GQ: t`Guiné Equatorial`,
    GR: t`Grécia`,
    GT: t`Guatemala`,
    GW: t`Guiné-Bissau`,
    GY: t`Guiana`,
    HN: t`Honduras`,
    HR: t`Croácia`,
    HT: t`Haiti`,
    HU: t`Hungria`,
    ID: t`Indonésia`,
    IE: t`Irlanda`,
    IL: t`Israel`,
    IN: t`Índia`,
    IQ: t`Iraque`,
    IR: t`Irão`,
    IS: t`Islândia`,
    IT: t`Itália`,
    JM: t`Jamaica`,
    JO: t`Jordânia`,
    JP: t`Japão`,
    KE: t`Quénia`,
    KG: t`Quirguistão`,
    KH: t`Cambodja`,
    KI: t`Kiribati`,
    KM: t`Comoros`,
    KN: t`São Cristóvão e Nevis`,
    KP: t`Coreia do Norte`,
    KR: t`Coreia do Sul`,
    KW: t`Kuwait`,
    KZ: t`Cazaquistão`,
    LA: t`Laos`,
    LB: t`Líbano`,
    LC: t`Santa Lúcia`,
    LI: t`Liechtenstein`,
    LK: t`Sri Lanka`,
    LR: t`Libéria`,
    LS: t`Lesoto`,
    LT: t`Lituânia`,
    LU: t`Luxemburgo`,
    LV: t`Letónia`,
    LY: t`Líbia`,
    MA: t`Marrocos`,
    MC: t`Mónaco`,
    MD: t`Moldávia`,
    ME: t`Montenegro`,
    MG: t`Madagáscar`,
    MH: t`Ilhas Marshall`,
    MK: t`Macedónia do Norte`,
    ML: t`Mali`,
    MM: t`Myanmar`,
    MN: t`Mongólia`,
    MR: t`Mauritânia`,
    MT: t`Malta`,
    MU: t`Maurícia`,
    MV: t`Maldivas`,
    MW: t`Malawi`,
    MX: t`México`,
    MY: t`Malásia`,
    MZ: t`Moçambique`,
    NA: t`Namíbia`,
    NE: t`Níger`,
    NG: t`Nigéria`,
    NI: t`Nicarágua`,
    NL: t`Países Baixos`,
    NO: t`Noruega`,
    NP: t`Nepal`,
    NR: t`Nauru`,
    NU: t`Niue`,
    NZ: t`Nova Zelândia`,
    OM: t`Omã`,
    PA: t`Panamá`,
    PE: t`Peru`,
    PG: t`Papua-Nova Guiné`,
    PH: t`Filipinas`,
    PK: t`Paquistão`,
    PL: t`Polónia`,
    PS: t`Palestina`,
    PT: t`Portugal`,
    PW: t`Palau`,
    PY: t`Paraguai`,
    QA: t`Qatar`,
    RO: t`Roménia`,
    RS: t`Sérvia`,
    RU: t`Rússia`,
    RW: t`Ruanda`,
    SA: t`Arábia Saudita`,
    SB: t`Ilhas Salomão`,
    SC: t`Seicheles`,
    SD: t`Sudão`,
    SE: t`Suécia`,
    SG: t`Singapura`,
    SI: t`Eslovénia`,
    SK: t`Eslováquia`,
    SL: t`Serra Leoa`,
    SM: t`San Marino`,
    SN: t`Senegal`,
    SO: t`Somália`,
    SR: t`Suriname`,
    SS: t`Sudão do Sul`,
    ST: t`São Tomé e Príncipe`,
    SV: t`El Salvador`,
    SY: t`Síria`,
    SZ: t`Essuatíni`,
    TD: t`Chade`,
    TG: t`Togo`,
    TH: t`Tailândia`,
    TJ: t`Tajiquistão`,
    TL: t`Timor-Leste`,
    TM: t`Turquemenistão`,
    TN: t`Tunísia`,
    TO: t`Tonga`,
    TR: t`Turquia`,
    TT: t`Trinidade e Tobago`,
    TV: t`Tuvalu`,
    TW: t`Taiwan`,
    TZ: t`Tanzânia`,
    UA: t`Ucrânia`,
    UG: t`Uganda`,
    US: t`Estados Unidos da América`,
    UY: t`Uruguai`,
    UZ: t`Uzbequistão`,
    VA: t`Vaticano`,
    VC: t`São Vicente e Granadinas`,
    VE: t`Venezuela`,
    VN: t`Vietname`,
    VU: t`Vanuatu`,
    WS: t`Samoa`,
    YE: t`Iémen`,
    ZA: t`África do Sul`,
    ZM: t`Zâmbia`,
    ZW: t`Zimbabwe`,
  }[code]);
