import React from 'react';
import classnames from 'classnames';

const OpponentPoints = ({ label, index, points, pointsGiven, setPoints }) => (
  <>
    <label className="label">{label}</label>
    <div className="field has-addons">
      <div className="control">
        <button
          disabled={pointsGiven[0] === 1 && points !== 0}
          type="button"
          className={classnames('button', {
            'is-success': points === 0,
          })}
          onClick={() => setPoints(index, points === 0 ? undefined : 0)}
        >
          0
        </button>
      </div>
      <div className="control">
        <button
          disabled={pointsGiven[1] === 3 && points !== 1}
          type="button"
          className={classnames('button', {
            'is-warning': points === 1,
          })}
          onClick={() => setPoints(index, points === 1 ? undefined : 1)}
        >
          1
        </button>
      </div>
      <div className="control">
        <button
          disabled={pointsGiven[2] === 3 && points !== 2}
          type="button"
          className={classnames('button', {
            'is-warning': points === 2,
          })}
          onClick={() => setPoints(index, points === 2 ? undefined : 2)}
        >
          2
        </button>
      </div>
      <div className="control">
        <button
          disabled={pointsGiven[3] === 1 && points !== 3}
          type="button"
          className={classnames('button', {
            'is-danger': points === 3,
          })}
          onClick={() => setPoints(index, points === 3 ? undefined : 3)}
        >
          3
        </button>
      </div>
    </div>
  </>
);

export default OpponentPoints;
