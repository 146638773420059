export const quizzesOrder = [
  'wqc',
  'eqc',
  'cnq',
  'hot_100',
  'squizzed',
  'inquizicao',
  '662211',
  'unlimited',
  'dairy',
];

export const quizTypeAbbr = {
  wqc: {
    abbr: 'WQC',
    includeMonth: false,
  },
  eqc: {
    abbr: 'EQC',
    includeMonth: false,
  },
  cnq: {
    abbr: 'CNQ',
    includeMonth: false,
  },
  hot_100: {
    abbr: 'HOT',
    includeMonth: true,
  },
  squizzed: {
    abbr: 'SQ',
    includeMonth: true,
  },
  inquizicao: {
    abbr: 'INQ',
    includeMonth: true,
  },
  662211: {
    abbr: '621',
    includeMonth: true,
  },
  unlimited: {
    abbr: 'UNL',
    includeMonth: true,
  },
  dairy: {
    abbr: 'QD',
    includeMonth: true,
  },
};
