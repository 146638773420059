import React, { useState, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import classnames from 'classnames';

import routes from 'routes';
import { useStateValue } from 'state/State';
import { catalogs } from 'utils/catalogs';
import RouteWithTitle from 'utils/RouteWithTitle';
import Header from 'partials/Header';
import Notifications from 'partials/Notifications';
import Message from 'partials/Message';
import Blocked from 'components/Blocked';
import Logger from 'components/Logger';
import Error from 'components/Error';

const AppBody = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [
    {
      user,
      settings: { language },
    },
  ] = useStateValue();

  const [online, setOnline] = useState(navigator.onLine);

  window.addEventListener('load', function () {
    window.addEventListener('online', () => {
      setOnline(navigator.onLine);
    });
    window.addEventListener('offline', () => {
      setOnline(navigator.onLine);
    });
  });

  useEffect(() => {
    if (!online) {
      toast.error(<Trans>Perdeste a ligação à internet.</Trans>);
    }
  }, [online]);

  const standalone = queryParams.standalone;

  return (
    <>
      {!standalone && <Header />}
      <Notifications />
      <main className={classnames('section', { standalone: standalone })}>
        <div className="container">
          {online ? (
            <Switch>
              {routes.map((route) => {
                const title =
                  route.path === '/'
                    ? process.env.REACT_APP_NAME
                    : `${process.env.REACT_APP_NAME} | ${
                        catalogs[language].messages[route.title.props.id]
                      }`;
                const newProps = {
                  ...route,
                  title,
                  exact: !!route.path && !route.loose,
                };
                if (user && user.valid_roles.blocked && !route.free) {
                  newProps.component = Blocked;
                }
                return <RouteWithTitle key={title} {...newProps} />;
              })}
            </Switch>
          ) : (
            <Error status={666} />
          )}
        </div>
      </main>
      {online && <Message />}
      <ToastContainer />
      {user && online && <Logger />}
    </>
  );
};

export default AppBody;
