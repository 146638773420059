import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import * as Plurals from 'make-plural/plurals';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import '@fortawesome/fontawesome-free/js/all';
import 'react-datepicker/dist/react-datepicker.css';

import { useStateValue } from 'state/State';
import { catalogs } from 'utils/catalogs';
import Auth from 'utils/Auth';
import 'styles/App.scss';

import AppBody from './AppBody';

// Lingui
const { messages } = catalogs[process.env.REACT_APP_LANGUAGE];
i18n.loadLocaleData(process.env.REACT_APP_LANGUAGE, {
  plurals: Plurals[process.env.REACT_APP_LANGUAGE],
});
i18n.load(process.env.REACT_APP_LANGUAGE, messages);
i18n.activate(process.env.REACT_APP_LANGUAGE);

// Date picker
registerLocale('pt', pt);

const App = () => {
  const [
    {
      settings: { language },
    },
  ] = useStateValue();
  setDefaultLocale(language);

  return (
    <Auth>
      <I18nProvider i18n={i18n}>
        <BrowserRouter>
          <AppBody />
        </BrowserRouter>
      </I18nProvider>
    </Auth>
  );
};

export default App;
