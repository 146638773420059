import React from 'react';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';

import classes from './Ranking.module.scss';

const TableHeader = () => (
  <thead>
    <tr>
      <th className={classes.rankCell}>#</th>
      <th>
        <Trans>Nome</Trans>
      </th>
      <th className="has-text-centered">
        <span
          className="icon has-tooltip-bottom"
          data-tooltip={t`Pontos na liga`}
        >
          <Trans>Pts</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span
          className="icon has-tooltip-bottom"
          data-tooltip={t`Pontos a favor`}
        >
          <Trans>PaF</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span
          className="icon has-tooltip-bottom"
          data-tooltip={t`Pontos contra`}
        >
          <Trans>PC</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span
          className="icon has-tooltip-bottom"
          data-tooltip={t`Diferença de pontos`}
        >
          <Trans>DdP</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span className="icon has-tooltip-bottom" data-tooltip={t`Jogos`}>
          <Trans>J</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span className="icon has-tooltip-bottom" data-tooltip={t`Faltas`}>
          <Trans>F</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span className="icon has-tooltip-bottom" data-tooltip={t`Vitórias`}>
          <Trans>V</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span className="icon has-tooltip-bottom" data-tooltip={t`Empates`}>
          <Trans>E</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span className="icon has-tooltip-bottom" data-tooltip={t`Derrotas`}>
          <Trans>D</Trans>
        </span>
      </th>
      <th className="has-text-centered">
        <span
          className="icon has-tooltip-bottom has-tooltip-left"
          data-tooltip={t`Respostas correctas`}
        >
          <Trans>RC</Trans>
        </span>
      </th>
    </tr>
  </thead>
);

export default TableHeader;
